import PropTypes from 'prop-types'


export const model = [
  {
    key: 'id',
    label: 'Id',
    column: {
      visible: true,
      width: 80,
    },
    prop: {
      type: PropTypes.number,
      value: null,
    }
  }, {
    key: 'accountName',
    label: 'Fleet',
    column: {
      visible: true,
      width: 80,
    },
    prop: {
      type: PropTypes.string,
      value: '',
    }
  }, {
    key: 'driverNote',
    label: 'Driver Note',
    column: {
      visible: false,
      width: 120,
    },
    prop: {
      type: PropTypes.string,
      value: '',
    }
  }, {
    key: 'driver',
    label: 'Driver',
    column: {
      visible: true,
      width: 120,
    },
    prop: {
      type: PropTypes.string,
      value: '',
    }
  }, {
    key: 'costCentre',
    label: 'Cost Centre',
    column: {
      visible: true,
      width: 120,
    },
    prop: {
      type: PropTypes.string,
      value: '',
    }
  }, {
    key: 'incidentType',
    label: 'Incident Type',
    column: {
      visible: true,
      width: 120,
    },
    prop: {
      type: PropTypes.string,
      value: '',
    }
  }, {
    key: 'incidentDate',
    label: 'Incident Date Time',
    column: {
      visible: true,
      width: 210,
    },
    prop: {
      type: PropTypes.string,
      value: '',
    }
  }, {
    key: 'description',
    label: `Incident Details`,
    column: {
      visible: false,
      width: 220,
    },
    prop: {
      type: PropTypes.string,
      value: '',
    }
  }, {
    key: 'registration',
    label: 'Registration',
    column: {
      visible: true,
      width: 100,
    },
    prop: {
      type: PropTypes.string,
      value: '',
    }
  }, {
    key: 'year',
    label: 'Year',
    column: {
      visible: true,
      width: 100,
    },
    prop: {
      type: PropTypes.number,
      value: null,
    }
  }, {
    key: 'make',
    label: 'Make',
    column: {
      visible: true,
      width: 120,
    },
    prop: {
      type: PropTypes.string,
      value: '',
    }
  }, {
    key: 'model',
    label: 'Model',
    column: {
      visible: true,
      width: 120,
    },
    prop: {
      type: PropTypes.string,
      value: '',
    }
  }, {
    key: 'variant',
    label: 'Variant',
    column: {
      visible: true,
      width: 120,
    },
    prop: {
      type: PropTypes.string,
      value: '',
    }
  }, {
    key: 'attachmentsCount',
    label: 'No. of Files',
    column: {
      visible: false,
      width: 120,
    },
    prop: {
      type: PropTypes.number,
      value: null,
    }
  }, {
    key: 'documentsCount',
    label: 'Documents',
    column: {
      visible: false,
      width: 100,
    },
    prop: {
      type: PropTypes.number,
      value: null,
    }
  }, {
    key: 'notes',
    label: 'Notes',
    column: {
      visible: false,
      width: 100,
    },
    prop: {
      type: PropTypes.number,
      value: null,
    }
  }
]

const config = {
  idKey: 'id',
  title: 'Incident Management',
  breadcrumbs: null,
  backgroundImage: 'url("/static/images/backgrounds/bg4.png")',
  api: {
    filter: '/filteroptions/incidentmanagement',
    resource: `/report/incidentmanagement`,
    download: `/download/incidentmanagement`,
  },
  model,
  headers: [
    'accountName', 'driverNote', 'driver', 'costCentre', 'incidentType', 'incidentDate', 'description',
    'registration', 'year', 'make', 'model', 'variant', 'attachmentsCount', 'documentsCount', 'notes'
  ],
  filters: [],
}


export default config
