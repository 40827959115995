import React, { createContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'
import Typography from '@mui/material/Typography'
import { Dialog } from 'frame/components'
import { useNavigate, useRouter } from 'utils'
import { constants } from '../../module'
import Form from './form'
import ReactGA from 'react-ga'

import { formTabs } from "../../config"
import TabMenu from "../../../../features/fleet/components/drill-down-modal/tab-menu"
import StyledModalV2, { StyledModalV2Actions } from 'frame/components/styled-modal-v2'

export const NewVehicleSummaryContext = createContext({})

const New = ({
  relatedContract,
  fleetIds,
  fetchContractStatus,
  saveStatus,
  onFetchContract,
  onSave,
  onLookup,
  onReset,
  errorMessage,
  userRoles
}) => {
  const navigate = useNavigate()
  const [saved, setSaved] = useState(false)
  
  const [confirmSaveDialogOpen, setConfirmSaveDialogOpen] = useState(false);

  const [activeTab, setActiveTab] = useState(formTabs?.[0]?.value ?? "")
  const [disabledTabs, setDisabledTabs] = useState({
    leaseInformation: false,
    vehicleInformation: true,
    additionalServices: true,
    accessories: true,
  });

  const [values, setValues] = useState({
  })

  const onClose = navigate(constants.PATH_ALL)
  const onCloseHandler = () => {
    onReset()
    //onClose()
    setConfirmSaveDialogOpen(true)
  }

  const onSubmit = (payload) => {
    console.log('[GA] event tracked', { category: 'Request', action: 'Submit New', label: 'Vehicle Order Summary' })
    ReactGA.event({ category: 'Request', action: 'Submit New', label: 'Vehicle Order Summary' })
    onSave(payload)
    setSaved(true)
  }

  useEffect(() => {
    if (saved && saveStatus === 'processed') {
      onClose()
    }
    navigate(`${match.url}${constants.PATH_LEASE_INFORMATION}`)(0)
  }, [saveStatus])
  
  const isSaving = saveStatus === 'processing'
  const savingError = saveStatus === 'error'

  const { match } = useRouter()

  const tabsConfig = [
    {
      label: 'Lease Information',
      path: `${match.url}${constants.PATH_LEASE_INFORMATION}`,
      visible: true,
      disabled: disabledTabs.leaseInformation,
      isDataAvailable: (values?.requestType &&
        values?.accountName &&
        values?.leaseType &&
        values?.term &&
        values?.odometer ),
    }, {
      label: 'Vehicle Information',
      path: `${match.url}${constants.PATH_VEHICLE_INFORMATION}`,
      visible: true,
      disabled: disabledTabs.vehicleInformation,
      isDataAvailable: (values?.make
        && values?.model ),
    }, {
      label: 'Additional Services',
      path: `${match.url}${constants.PATH_ADDITIONAL_SERVICES}`,
      visible: true,
      disabled: disabledTabs.additionalServices,
      isDataAvailable: (values?.hasFuelCardAndReporting 
        || values?.hasFuelCardAndReporting
        || values?.hasReliefVehicle
        || values?.hasRucManagement
        || values?.hasAccidentManagement
        || values?.hasTollManagement
        || values?.hasInfringementManagement
        || values?.hasFbtReporting
        || values?.hasTelematics
        || values?.hasFleetPortal
        || values?.hasDriverApp
        || values?.hasRoadsideAssistance
        || values?.additionalServiceNotes ),
    }, {
      label: 'Accessories',
      path: `${match.url}${constants.PATH_ACCESSORIES}`,
      visible: true,
      disabled: disabledTabs.accessories,
      isDataAvailable: (values?.hasCanopySlideWindows
        || values?.hasCanopyLiftupWindows
        || values?.hasCentralLockingForCanopySelection
        || values?.hasRubberMats
        || values?.hasCarpetMats
        || values?.hasDeckliner
        || values?.hasHardlid
        || values?.hasSoftTonneauCover
        || values?.hasTowbarWiring
        || values?.hasRoofRacks
        || values?.hasSeatCovers
        || values?.hasFirstAidKit
        || values?.hasFireExtinguisher
        || values?.hasCargoBarrier
        || values?.hasTyreUpgrade
        || values?.hasSignWriting
        || values?.hasSportsBar
        || values?.hasNudgeBar
        || values?.canopySlideWindowsNotes
        || values?.canopyLiftupWindowsNotes
        || values?.centralLockingForCanopySelectionNotes
        || values?.rubberMatsNotes
        || values?.carpetMatsNotes
        || values?.decklinerNotes
        || values?.hardlidNotes
        || values?.softTonneauCoverNotes
        || values?.towbarWiringNotes
        || values?.roofRacksNotes
        || values?.seatCoversNotes
        || values?.firstAidKitNotes
        || values?.fireExtinguisherNotes
        || values?.cargoBarrierNotes
        || values?.tyreUpgradeNotes
        || values?.signWritingNotes
        || values?.sportsBarNotes
        || values?.nudgeBarNotes )
    },
  ].filter(x => x.visible)

  /**
   * This effect will trigger navigation
   */
  useEffect(() => {
    const tabFromUrl = window.location.pathname?.split('/').pop();
    setActiveTab(tabFromUrl || formTabs[0].value);
  }, [window.location.pathname]);

  return (
    <>
      <Dialog
        open
        autoSize
        size="md"
        aria-labelledby="Request Vehicle Order"
        breadcrumbs=""
        title={`Request Vehicle Order`}
        loading={isSaving}
        onClose={onCloseHandler}
        tabsConfig={tabsConfig}
        tabMenu={<TabMenu config={tabsConfig} />}
      >
        <Grid container>
          <Grid item md={12} xs={12}>
            <NewVehicleSummaryContext.Provider value={{
              activeTab,
              setActiveTab,
              disabledTabs,
              setDisabledTabs,
              values,
              setValues,
            }}>
              <Form
                relatedContract={relatedContract}
                disabled={isSaving}
                fleetIds={fleetIds}
                error={savingError}
                fetchContractStatus={fetchContractStatus}
                onClose={onCloseHandler}
                onFetchContract={onFetchContract}
                onSubmit={onSubmit}
                onLookup={onLookup}
                errorMessage={errorMessage}
                userRoles={userRoles}
                activeTab={activeTab}
              />
            </NewVehicleSummaryContext.Provider>
          </Grid>
        </Grid>
      </Dialog>
      <StyledModalV2
        open={confirmSaveDialogOpen}
        onClose={() => setConfirmSaveDialogOpen(false)}
        content={
          <Typography variant="h4" textAlign="center">
            Any changes will not be saved. Are you sure you want to continue?
          </Typography>
        }
        actions={
          <StyledModalV2Actions
            onCancel={() => setConfirmSaveDialogOpen(false)}
            onConfirm={() => {
              setConfirmSaveDialogOpen(false);
              onClose();
            }}
          />
        }
      />
    </>
  )
}

New.propTypes = {
  relatedContract: PropTypes.object.isRequired,
  fleetIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  fetchContractStatus: PropTypes.string,
  saveStatus: PropTypes.string,
  onFetchContract: PropTypes.func.isRequired,
  onLookup: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  userRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
  onSave: PropTypes.func.isRequired,
}

export default New