import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { trimObjectStrings } from 'utils'
import Item from './item'
import { fetch,
  update,
  remove 
} from '../../actions'

const mapStateToProps = (state, ownProps) => {
  const id = ownProps.match.params?.id
  const dataOrder = state.requestVehicleSummary.data?.[id] || null
  const fetchStatus = state.requestVehicleSummary.flags?.fetch || 'none'
  const saveStatus = state.requestVehicleSummary.flags?.update || 'none'
  const fleetIds = state.settings.fleet.selected
  const userRoles = state.settings.roles

  let data = {
    vehicleOrderDetails: trimObjectStrings(dataOrder), // Because the API does not clean data for us
  }

  return {
    id,
    data,
    fleetIds,
    fetchStatus,
    saveStatus,
    userRoles
  }
}

const mapDispatchToProps = (dispatch) => ({
  onFetch: (id) => {
    dispatch(fetch(null, {
      resourceId: id
    }))
  },
  onUpdate: (payload) => {
    dispatch(update(payload, {
      resourceId: payload.id,
    }))
  },
  onRemove: (id) => {
    dispatch(remove(null, {
      resourceId: id,
    }))
  },
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Item))