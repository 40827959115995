import React, { useContext, useState, useEffect} from 'react'
import {Box, Button, Divider, Stack, Typography} from '@mui/material'
import MuiTextfield from 'frame/components/mui-textfield'
import { useNavigate } from 'utils'
import { constants } from '../../../module'
import StyledModalV2, { StyledModalV2Actions } from 'frame/components/styled-modal-v2'
import PropTypes from 'prop-types'

import { AccessoriesForm } from './accessories-form'
import { useTheme } from '@mui/material/styles'
import { NewVehicleSummaryContext } from '../new'
import { FormContext } from '../form'
import { Alert } from 'frame/components'
import { useForm, Controller } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { schema } from './form-schema'
import { useSelectedFleets } from "features/settings"

const Accessories = ({
  onSubmit,
  error,
  errorMessage,
}) => {
  const newContext = useContext(NewVehicleSummaryContext);
  const formContext = useContext(FormContext);
  const navigate = useNavigate()
  const theme = useTheme()
  const {
    transferValues,
    onInputChange,
    values,
    setValues,
    data,
  } = formContext

  const methods = useForm({
    resolver: zodResolver(schema),
    reValidateMode: "all",
  })

  const accessoriesMenu = [
    {
      title: "Canopy Slide Windows",
      name: "hasCanopySlideWindows",
      notesname: "canopySlideWindowsNotes"
    },
    {
      title: "Canopy Lift Up windows",
      name: "hasCanopyLiftupWindows",
      notesname: "canopyLiftupWindowsNotes"
    },
    {
      title: "Central Locking for Canopy Selection",
      name: "hasCentralLockingForCanopySelection",
      notesname: "centralLockingForCanopySelectionNotes"
    },
    {
      title: "Rubber Mats",
      name: "hasRubberMats",
      notesname: "rubberMatsNotes"
    },
    {
      title: "Carpet Mats",
      name: "hasCarpetMats",
      notesname: "carpetMatsNotes"
    },
    {
      title: "Deckliner",
      name: "hasDeckliner",
      notesname: "decklinerNotes"
    },
    {
      title: "Hardlid",
      name: "hasHardlid",
      notesname: "hardlidNotes"
    },
    {
      title: "Soft Tonneau Cover",
      name: "hasSoftTonneauCover",
      notesname: "softTonneauCoverNotes"
    },
    {
      title: "Towbar & Wiring",
      name: "hasTowbarWiring",
      notesname: "towbarWiringNotes"
    },
    {
      title: "Roofracks",
      name: "hasRoofRacks",
      notesname: "roofRacksNotes"
    },
    {
      title: "Seat Covers",
      name: "hasSeatCovers",
      notesname: "seatCoversNotes"
    },
    {
      title: "First Aid kit",
      name: "hasFirstAidKit",
      notesname: "firstAidKitNotes"
    },
    {
      title: "Fire Extinguisher",
      name: "hasFireExtinguisher",
      notesname: "fireExtinguisherNotes"
    },
    {
      title: "Cargo Barrier",
      name: "hasCargoBarrier",
      notesname: "cargoBarrierNotes"
    },
    {
      title: "Tyre Upgrade",
      name: "hasTyreUpgrade",
      notesname: "tyreUpgradeNotes"
    },
    {
      title: "Signwriting",
      name: "hasSignWriting",
      notesname: "signWritingNotes"
    },
    {
      title: "Sports Bar",
      name: "hasSportsBar",
      notesname: "sportsBarNotes"
    },
    {
      title: "Nudge Bar",
      name: "hasNudgeBar",
      notesname: "nudgeBarNotes"
    },
  ]

  const leftItems = accessoriesMenu.slice(0, 9);
  const rightItems = accessoriesMenu.slice(9, 18);

  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [confirmSaveDialogOpen, setConfirmSaveDialogOpen] = useState(false);
  const [status, setStatus] = useState('');
  const [message, setMessage] = useState('');
  const [validationMessage, setValidationMessage] = useState('');

  const userSelectedFleets = useSelectedFleets()

  const onClose = navigate(constants.PATH_ALL)

  const handleConfirmClose = () => {
    setConfirmDialogOpen(false);
    navigate(constants.PATH_ALL);
  };

  const onSubmitForm = () => {
    values.status = status;
    if (values.status === 'Submitted') {
      setMessage('Vehicle order request submitted successfully.');
    } else if (data?.vehicleOrderDetails?.status === 'Draft') {
      setMessage('Vehicle order request updated successfully.');
    } else {
      setMessage('Vehicle order request created successfully.');
    }
    if(values.status === 'Submitted') {
      if(values.make && values.model && values.requestType && values.leaseType && values.accountName && values.term && values.odometer){
        if(values.vehicleRegistration && typeof values.vehicleRegistration === 'object' && values.vehicleRegistration !== null ) {
          values.vehicleRegistration = values.vehicleRegistration?.value;
        }
        if(values.thirdPartyNumber && values.accountName){
          const fleet = userSelectedFleets.filter(f => f.value === values.thirdPartyNumber)[0]
          const accountName = fleet ? fleet.label : null
          values.accountName = accountName;
        }
        onSubmit(values)
        setConfirmDialogOpen(true);
      } else {
        setValidationMessage('Please complete mandatory fields* in Lease Information and Vehicle Information tabs.')
      }
    } else {
      if(values.requestType && values.leaseType && values.accountName && values.term && values.odometer){
        if(values.vehicleRegistration && typeof values.vehicleRegistration === 'object' && values.vehicleRegistration !== null ) {
          values.vehicleRegistration = values.vehicleRegistration?.value;
        }
        if(values.thirdPartyNumber && values.accountName){
          const fleet = userSelectedFleets.filter(f => f.value === values.thirdPartyNumber)[0]
          const accountName = fleet ? fleet.label : null
          values.accountName = accountName;
        }
        onSubmit(values)
        setConfirmDialogOpen(true);
      } else {
        setValidationMessage('Please complete mandatory fields* in Lease Information tab.')
      }
    }
  };

  useEffect(() => {
    if (!data) {
      return
    }
    const vehicleOrderDetails = data?.vehicleOrderDetails;
    setValues((values) => ({
      ...values,
      hasCanopySlideWindows: vehicleOrderDetails.hasCanopySlideWindows && !values.hasCanopySlideWindows ? vehicleOrderDetails.hasCanopySlideWindows : values.hasCanopySlideWindows,
      canopySlideWindowsNotes: vehicleOrderDetails.canopySlideWindowsNotes && !values.canopySlideWindowsNotes ? vehicleOrderDetails.canopySlideWindowsNotes : values.canopySlideWindowsNotes,
      hasCanopyLiftupWindows: vehicleOrderDetails.hasCanopyLiftupWindows && !values.hasCanopyLiftupWindows ? vehicleOrderDetails.hasCanopyLiftupWindows : values.hasCanopyLiftupWindows,
      canopyLiftupWindowsNotes: vehicleOrderDetails.canopyLiftupWindowsNotes && !values.canopyLiftupWindowsNotes ? vehicleOrderDetails.canopyLiftupWindowsNotes : values.canopyLiftupWindowsNotes,
      hasCentralLockingForCanopySelection: vehicleOrderDetails.hasCentralLockingForCanopySelection && !values.hasCentralLockingForCanopySelection ? vehicleOrderDetails.hasCentralLockingForCanopySelection : values.hasCentralLockingForCanopySelection,
      centralLockingForCanopySelectionNotes: vehicleOrderDetails.centralLockingForCanopySelectionNotes && !values.centralLockingForCanopySelectionNotes ? vehicleOrderDetails.centralLockingForCanopySelectionNotes : values.centralLockingForCanopySelectionNotes,
      hasRubberMats: vehicleOrderDetails.hasRubberMats && !values.hasRubberMats ? vehicleOrderDetails.hasRubberMats : values.hasRubberMats,
      rubberMatsNotes: vehicleOrderDetails.rubberMatsNotes && !values.rubberMatsNotes ? vehicleOrderDetails.rubberMatsNotes : values.rubberMatsNotes,
      hasCarpetMats: vehicleOrderDetails.hasCarpetMats && !values.hasCarpetMats ? vehicleOrderDetails.hasCarpetMats : values.hasCarpetMats,
      carpetMatsNotes: vehicleOrderDetails.carpetMatsNotes && !values.carpetMatsNotes ? vehicleOrderDetails.carpetMatsNotes : values.carpetMatsNotes,
      hasDeckliner: vehicleOrderDetails.hasDeckliner && !values.hasDeckliner ? vehicleOrderDetails.hasDeckliner : values.hasDeckliner,
      decklinerNotes: vehicleOrderDetails.decklinerNotes && !values.decklinerNotes ? vehicleOrderDetails.decklinerNotes : values.decklinerNotes,
      hasHardlid: vehicleOrderDetails.hasHardlid && !values.hasHardlid ? vehicleOrderDetails.hasHardlid : values.hasHardlid,
      hardlidNotes: vehicleOrderDetails.hardlidNotes && !values.hardlidNotes ? vehicleOrderDetails.hardlidNotes : values.hardlidNotes,
      hasSoftTonneauCover: vehicleOrderDetails.hasSoftTonneauCover && !values.hasSoftTonneauCover ? vehicleOrderDetails.hasSoftTonneauCover : values.hasSoftTonneauCover,
      softTonneauCoverNotes: vehicleOrderDetails.softTonneauCoverNotes && !values.softTonneauCoverNotes ? vehicleOrderDetails.softTonneauCoverNotes : values.softTonneauCoverNotes,
      hasTowbarWiring: vehicleOrderDetails.hasTowbarWiring && !values.hasTowbarWiring ? vehicleOrderDetails.hasTowbarWiring : values.hasTowbarWiring,
      towbarWiringNotes: vehicleOrderDetails.towbarWiringNotes && !values.towbarWiringNotes ? vehicleOrderDetails.towbarWiringNotes : values.towbarWiringNotes,
      hasRoofRacks: vehicleOrderDetails.hasRoofRacks && !values.hasRoofRacks ? vehicleOrderDetails.hasRoofRacks : values.hasRoofRacks,
      roofRacksNotes: vehicleOrderDetails.roofRacksNotes && !values.roofRacksNotes ? vehicleOrderDetails.roofRacksNotes : values.roofRacksNotes,
      hasSeatCovers: vehicleOrderDetails.hasSeatCovers && !values.hasSeatCovers ? vehicleOrderDetails.hasSeatCovers : values.hasSeatCovers,
      seatCoversNotes: vehicleOrderDetails.seatCoversNotes && !values.seatCoversNotes ? vehicleOrderDetails.seatCoversNotes : values.seatCoversNotes,
      hasFirstAidKit: vehicleOrderDetails.hasFirstAidKit && !values.hasFirstAidKit ? vehicleOrderDetails.hasFirstAidKit : values.hasFirstAidKit,
      firstAidKitNotes: vehicleOrderDetails.firstAidKitNotes && !values.firstAidKitNotes ? vehicleOrderDetails.firstAidKitNotes : values.firstAidKitNotes,
      hasFireExtinguisher: vehicleOrderDetails.hasFireExtinguisher && !values.hasFireExtinguisher ? vehicleOrderDetails.hasFireExtinguisher : values.hasFireExtinguisher,
      fireExtinguisherNotes: vehicleOrderDetails.fireExtinguisherNotes && !values.fireExtinguisherNotes ? vehicleOrderDetails.fireExtinguisherNotes : values.fireExtinguisherNotes,
      hasCargoBarrier: vehicleOrderDetails.hasCargoBarrier && !values.hasCargoBarrier ? vehicleOrderDetails.hasCargoBarrier : values.hasCargoBarrier,
      cargoBarrierNotes: vehicleOrderDetails.cargoBarrierNotes && !values.cargoBarrierNotes ? vehicleOrderDetails.cargoBarrierNotes : values.cargoBarrierNotes,
      hasTyreUpgrade: vehicleOrderDetails.hasTyreUpgrade && !values.hasTyreUpgrade ? vehicleOrderDetails.hasTyreUpgrade : values.hasTyreUpgrade,
      tyreUpgradeNotes: vehicleOrderDetails.tyreUpgradeNotes && !values.tyreUpgradeNotes ? vehicleOrderDetails.tyreUpgradeNotes : values.tyreUpgradeNotes,
      hasSignWriting: vehicleOrderDetails.hasSignWriting && !values.hasSignWriting ? vehicleOrderDetails.hasSignWriting : values.hasSignWriting,
      signWritingNotes: vehicleOrderDetails.signWritingNotes && !values.signWritingNotes ? vehicleOrderDetails.signWritingNotes : values.signWritingNotes,
      hasSportsBar: vehicleOrderDetails.hasSportsBar && !values.hasSportsBar ? vehicleOrderDetails.hasSportsBar : values.hasSportsBar,
      sportsBarNotes: vehicleOrderDetails.sportsBarNotes && !values.sportsBarNotes ? vehicleOrderDetails.sportsBarNotes : values.sportsBarNotes,
      hasNudgeBar: vehicleOrderDetails.hasNudgeBar && !values.hasNudgeBar ? vehicleOrderDetails.hasNudgeBar : values.hasNudgeBar,
      nudgeBarNotes: vehicleOrderDetails.nudgeBarNotes && !values.nudgeBarNotes ? vehicleOrderDetails.nudgeBarNotes : values.nudgeBarNotes,
      otherAccessories: vehicleOrderDetails.otherAccessories && !values.otherAccessories ? vehicleOrderDetails.otherAccessories : values.otherAccessories,

      id: vehicleOrderDetails?.id,
    }))
  }, [data])

  return (
    <>
      <form
        id="request-accessories-form"
        onSubmit={methods.handleSubmit(onSubmitForm)}
      >
        <Stack>
          {validationMessage && (
            <Stack gap={1}>
              <Alert>
                {validationMessage || 'Something went wrong while trying to submit vehicle order request.' }
              </Alert>
            </Stack>
          )}
          <Stack flexDirection={{ xs: "column", md: "row" }} gap={2}>
            <Stack flex={1}>
              <Stack direction="row" spacing={2}>
                <Stack flex={1}>
                  {leftItems.map((accessory) => (
                    <Stack
                      flexDirection="row"
                      alignItems="center"
                      gap={1}
                      py={1}
                      borderBottom="1px solid"
                      borderColor={theme.palette.neutral.mid}
                      key={accessory.name}
                    >
                      <Stack flex={1}>
                        <Typography variant="h6" maxWidth={190}>
                          {accessory.title}
                        </Typography>
                      </Stack>
                      <Stack flex={1}>
                        <AccessoriesForm
                          key={accessory.name}
                          name={accessory.name}
                          notesname={accessory.notesname}
                          label=''
                        />
                      </Stack>
                    </Stack>
                  ))}
                </Stack>
                <Stack flex={1}>
                  {rightItems.map((accessory) => (
                    <Stack
                      flexDirection="row"
                      alignItems="center"
                      gap={1}
                      py={1}
                      borderBottom="1px solid"
                      borderColor={theme.palette.neutral.mid}
                      key={accessory.name}
                    >
                      <Stack flex={1}>
                        <Typography variant="h6" maxWidth={190}>
                          {accessory.title}
                        </Typography>
                      </Stack>
                      <Stack flex={1}>
                        <AccessoriesForm
                          key={accessory.name}
                          name={accessory.name}
                          notesname={accessory.notesname}
                          label=''
                        />
                      </Stack>
                    </Stack>
                  ))}
                </Stack>
              </Stack>
            </Stack>
          </Stack>
          <Stack flexDirection={{ xs: "column", md: "row" }} gap={2} paddingTop={2}>
            <Stack width="100%">
              <Controller
                name="otherAccessories"
                control={methods.control}
                rules={{ required: false }}
                render={({ field }) => (
                  <>
                    <MuiTextfield
                      {...field}
                      multiline
                      rows={4}
                      id="otherAccessories"
                      label="Other Accessories"
                      inputProps={{ maxLength: 2000 }}
                      value={values.otherAccessories || ""}
                      onChange={onInputChange}
                    />
                  </>
                )}
              />
            </Stack>
          </Stack>
          <Divider sx={{ mt: 3, mb: 2, borderColor: theme.palette.accent.sky }} />

          <Stack
            flexDirection={{ xs: "column", md: "row" }}
            justifyContent="space-between"
            gap={1}
          >
            <Box width={{ xs: "100%", md: "205px" }}>
              <Button
                fullWidth
                color="error"
                variant="outlined"
                onClick={() => setConfirmSaveDialogOpen(true)}
              >
                Close
              </Button>
            </Box>
            <Stack flexDirection="row" gap={2}>
              <Box width={{ xs: "100%", md: "205px" }}>
                <Button
                  fullWidth
                  type="submit"
                  color="primary"
                  variant="outlined"
                >
                  Save and Exit
                </Button>
              </Box>
              <Box width={{ xs: "100%", md: "205px" }}>
                <Button
                  fullWidth
                  type="submit"
                  color="primary"
                  variant="contained"
                  onClick={() => setStatus('Submitted')} 
                >
                  Submit
                </Button>
              </Box>
            </Stack>
          </Stack>
        </Stack>
        <StyledModalV2
          open={confirmDialogOpen}
          onClose={() => {
            setConfirmDialogOpen(false);
            onClose();
          }}
          content={
            <Typography variant="h4" textAlign="center">
              {message}
            </Typography>
          }
        />
        <StyledModalV2
          open={confirmSaveDialogOpen}
          onClose={() => setConfirmSaveDialogOpen(false)}
          content={
            <Typography variant="h4" textAlign="center">
              Any changes will not be saved. Are you sure you want to continue?
            </Typography>
          }
          actions={
            <StyledModalV2Actions
              onCancel={() => setConfirmSaveDialogOpen(false)}
              onConfirm={() => {
                setConfirmSaveDialogOpen(false);
                onClose();
              }}
            />
          }
        />
      </form>
    </>
  )
}

Accessories.propTypes = {
  values: PropTypes.object.isRequired,
  onInputChange: PropTypes.func.isRequired,
  onSwitchChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
}

export default Accessories