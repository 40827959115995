import * as z from "zod";

export const schema = z.object({
  contractKm: z
    .string({ required_error: "This field is required" })
    .min(1, { message: "A valid km reading is required" })
    .refine(value => /^\d+$/.test(value), {
      message: 'Invalid Km',
    }),
  currentOdometer: z
    .string({ required_error: "This field is required" })
    .min(1, { message: "A valid odometer reading is required" })
    .refine(value => /^\d+$/.test(value), {
      message: 'Invalid odometer',
    }),
});
