import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { useProfileUpdater } from "../../hooks"
import { useNavigate } from 'utils'
import { constants } from '../../module'

import Alert from "@mui/material/Alert"
import Button from "@mui/material/Button"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import Tooltip from "@mui/material/Tooltip"
import Stack from "@mui/material/Stack"
import Snackbar from "@mui/material/Snackbar"
import { useTheme } from "@mui/material/styles"
import MuiTextfield from "frame/components/mui-textfield"

import StyledModalV2 from 'frame/components/styled-modal-v2'

import { useForm, Controller, FormProvider } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { schema } from "./schema"

const MAX_FLEET = 20

const Details = ({ fleets, profile, errorMessage }) => {
  const theme = useTheme()
  const navigate = useNavigate()
  const { loading, save, status } = useProfileUpdater()

  const [values, setValues] = useState({
    nickname: profile.nickname,
    email: profile.email,
    role: profile.role,
  })

  const [disableSave, setDisableSave] = useState(false)
  const [showAllFleets, setShowAllFleets] = useState(false)

  const maxSelectedFleets = fleets.length > MAX_FLEET
  const croppedSelectedfleets = maxSelectedFleets ? fleets.slice(0, MAX_FLEET): fleets

  const methods = useForm({
    resolver: zodResolver(schema),
    reValidateMode: "all",
    defaultValues: {
      nickname: profile.nickname,
      email: profile.email,
    }
  })

  const DisplayFleets = ({ fleets }) => {
    return (
      <Grid container py={2}>
        {fleets.map((fleet, index) => (
          <Grid key={index} item xs={6} sm={2} md={2}>
            <Stack>
              <Typography variant="bodyLarge" fontWeight={500} pb={1}>
                • {fleet.label}
              </Typography>
            </Stack>
          </Grid>
        ))}
      </Grid>
    )
  }

  const nicknameIsBlank = values.nickname && values.nickname.trim() === ""
  const unEdited = values.nickname === profile.nickname && values.email === profile.email

  const onInputChange = ({ target: { name, value } }) => {
    setValues({
      ...values,
      [name]: value
    })

    methods.setValue(name, value, {
      shouldValidate: true,
      shouldDirty: true,
    })
  }

  const onSubmit = () => {
    // clean white spaces
    setValues({
      ...values,
      nickname: values.nickname.trim()
    })
    
    console.log("[onSubmit]", JSON.stringify(values, null, 2))
    save(values)
  }

  const onClose = navigate(constants.PATH_ALL)

  // disables save button if fields are invalid
  useEffect(() => {    
    setDisableSave(nicknameIsBlank || unEdited)
  }, [nicknameIsBlank, unEdited, setDisableSave])

  return (
    <Stack
      p={3}
      gap={3}
    >
      <FormProvider>
        <form
          id="account-profile-form"
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <Stack
            borderRadius="5px"
            border="1px solid"
            borderColor={theme.palette.accent.sky}
            p={3}
            flexDirection="column"
            gap={2}
          >
            <Stack>
              <Typography variant="h5" fontWeight={600}>
                {profile.role}
              </Typography>
            </Stack>
            
            <Stack
              flexDirection={{ xs: "column", md: "row" }}
              gap={3}
            >
              <Stack gap={1} flex={1}>
                <Controller
                  name="nickname"
                  control={methods.control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <MuiTextfield
                      {...field}
                      id="nickname"
                      error={Boolean(methods.formState.errors?.nickname?.message)}
                      label="Name"
                      value={values.nickname || ""}
                      onChange={onInputChange}
                    />
                  )}
                />
                <Typography color="error" fontSize={12}>
                  {methods.formState.errors?.nickname?.message}
                </Typography>
              </Stack>

              <Stack gap={1} flex={1}>
                <Controller
                  disabled={true}
                  name="email"
                  control={methods.control}
                  rules={{ required: false }}
                  render={({ field }) => (
                    <MuiTextfield
                      {...field}
                      id="email"
                      error={
                        Boolean(methods.formState.errors?.email?.message)
                      }
                      label="Email"
                      value={values.email || ""}
                      onChange={onInputChange}
                    />
                  )}
                />
                <Typography color="error" fontSize={12}>
                  {methods.formState.errors?.email?.message}
                </Typography>
              </Stack>
            </Stack>

            <Stack alignItems="flex-end">
              <Stack sx={{ width: { xs: "100%", md: "210px"} }}>
                <Button
                  variant="contained"
                  fullWidth
                  disabled={loading || disableSave}
                  type="submit"
                >
                  Save Changes
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </form>
      </FormProvider>

      <Stack
        borderRadius="5px"
        border="1px solid"
        borderColor={theme.palette.accent.sky}
        p={3}
        flexDirection="column"
        gap={2}
      >
        <Stack>
          <Typography variant="h5" fontWeight={600}>
            Fleets ({fleets.length})
          </Typography>
        </Stack>

        <Stack>
          <DisplayFleets fleets={showAllFleets ? fleets : croppedSelectedfleets} />
        </Stack>

        <Stack>
          {maxSelectedFleets && (
            <Button variant="text">
              <Tooltip title={!showAllFleets ? `${fleets.length - MAX_FLEET} more` : "hide"}>
                <Typography
                  variant="bodyLarge"
                  component="span"
                  fontWeight={600}
                  sx={{
                    color: theme.palette.link
                  }}
                  onClick={() => setShowAllFleets(!showAllFleets)}
                >
                  {showAllFleets ? "Hide" : "Show All"}
                </Typography>
              </Tooltip>
            </Button>
          )}
        </Stack>
      </Stack>
      <StyledModalV2
        open={status === 'done'}
        status={status === 'done' ? 'success': 'error'}
        onClose={() => {
          onClose();
        }}
        content={
          <Typography variant="h4" textAlign="center">
            {'Profile updated successfully'}
          </Typography>
        }
      />
    </Stack>
  )
}

Details.propTypes = {
  fleets: PropTypes.array.isRequired,
  profile: PropTypes.object.isRequired,
  errorMessage: PropTypes.string,
}
export default Details
