import React, { useCallback, useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Listable } from 'listable'
import { useNavigate, capitalize, useRouter } from 'utils'
import { actions, config, constants } from '../../module'
import { useSelectedFleetIds, useSelectedPremiumFleets } from 'features/settings'
import { moduleName } from '../../actions/types'
import { setFbtCustomerView } from 'features/fbt-submissions/actions'
import PromotionBanner from 'features/fbt/promotion-banner'
import qs from 'query-string'
import Row from './row'

import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import SingleSelect from '../../../../frame/components/single-select'

const List = ({ model, currentFbtCustomerView, onFilter, onRefetch, onSelectFbtCustomer, onRequestFilters }) => {

  const fleetIds = useSelectedFleetIds()
  const linker = useNavigate()
  const { location } = useRouter()
  const fleetOptions = useSelectedPremiumFleets()

  const currentFleet = currentFbtCustomerView && fleetOptions.map(x => x.value).includes(currentFbtCustomerView)
    ? currentFbtCustomerView
    : fleetOptions[0]?.value
  
  const parsed = qs.parse(location.search)
  const tableName = `${capitalize(parsed.quarter)} ${parsed.year}`.trim()
  
  useEffect(() => {
    // onload redirect to latest quarter/year if not provided
    const yearsQuarters = model?.filters?.yearsQuarters
    !yearsQuarters && onRequestFilters(fleetIds)
    
    if(!location.search && yearsQuarters) { // redirect to latest quarter/year if not provided
      const quarter = yearsQuarters && yearsQuarters.length > 0 ? yearsQuarters[0].split(" - ")[1] : ''
      const year = yearsQuarters && yearsQuarters.length > 0 ? yearsQuarters[0].split(" - ")[0] : ''
      yearsQuarters && linker(`${constants.PATH_ALL}?year=${year}&quarter=${quarter}`)()
    }

    // when fleetid is not provided on report load for first time/after fleet changes 
    !parsed.fleetId && onSelectFbtCustomer(currentFleet)
    
  }, [fleetIds]) // eslint-disable-line

  useEffect(() => { // triggered on parsed quarter/fleetid changes (dropdown changes)
    if(parsed?.year && parsed?.quarter) {
      onRequestFilters(fleetIds)
      onFilter({ quarter: parsed.quarter, year: parsed.year, fleetId: parsed?.fleetId || currentFleet })
    }
  }, [parsed.quarter, parsed.fleetId]) // eslint-disable-line
  
  const renderItem = useCallback((item, index, itemProps) => {
    return (
      <Row key={index} item={item} {...itemProps} />
    ) // eslint-disable-next-line
  }, [])

  // Prevents re-rendering if ids or data updated - no longer needed
  const { ids, filters, ...rest } = model

  const onChangeFleet = (fleetId) => {
    onSelectFbtCustomer(fleetId)
    linker(`${constants.PATH_ALL}?year=${parsed.year}&quarter=${parsed.quarter}&fleetId=${fleetId}`)()
  }

  const showPromotionBanner = fleetOptions.length < 1 || !model?.filters?.yearsQuarters

  return (
    <Listable
      actions={actions}
      config={{ ...config, tableName }} // inject new config for dynamic table name
      model={rest}
      renderItem={renderItem}
      actionButtons={
        <>
          <Stack
            flexDirection={{ xs: 'column', md: 'row' }}
            justifyContent='space-between'
            gap={2}
            paddingTop={1}
          >
            { !showPromotionBanner && (
              <Box 
                width={{ xs: '100%', md: '205px' }}
                sx={{
                  backgroundColor: '#FFF',
                  width: 300,
                }}
              >
                <SingleSelect
                  noNone tight
                  id="pageHeaderDropdown"
                  label={fleetOptions.length === 0 ? 'No FBT Customers Available' : ''}
                  options={fleetOptions}
                  value={currentFleet}
                  onChange={onChangeFleet || fleetOptions[0]?.value}
                  disabled={fleetOptions.length <= 1}
                />
              </Box>
            )}
          </Stack>
        </>
      }  
      dropdown={{
        label: fleetOptions.length === 0 ? 'No FBT Customers Available' : '',
        value: currentFleet,
        options: fleetOptions,
        onChange: onChangeFleet || fleetOptions[0]?.value,
        hide: showPromotionBanner,
        disable: fleetOptions.length <= 1
      }}
      customActions={{ onRefetch }}
      maskComponent={showPromotionBanner ? <PromotionBanner /> : null}
    />
  )
}

List.propTypes = {
  model: PropTypes.object.isRequired,
  currentFbtCustomerView: PropTypes.string,
  // func
  onFilter: PropTypes.func.isRequired,
  onRefetch: PropTypes.func.isRequired,
  onSelectFbtCustomer: PropTypes.func.isRequired,
  onRequestFilters: PropTypes.func.isRequired,
}

const mapStateToProps = ({ fbtSummary, fbtSubmissions }) => ({
  model: fbtSummary,
  currentFbtCustomerView: fbtSubmissions.currentFbtCustomerView,
})

const mapDispatchToProps = (dispatch) => ({
  onFilter: (payload) => {
    dispatch({ type: `${moduleName}_update_filters`, payload })
  },
  onRefetch: () => {
    dispatch({ type: `${moduleName}_update_search_key`, payload: '' })
    dispatch({ type: `${moduleName}_refetch_resource` })
  },
  onSelectFbtCustomer: (payload) => {
    dispatch(setFbtCustomerView(payload))
  },
  onRequestFilters: (fleetIds) => {
    const FleetId = fleetIds.join(',')
    dispatch({
      type: `${moduleName}_request_filters`,
      payload: null,
      options: { queryParams: { FleetId } }
    })
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(List)
