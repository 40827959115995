import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { Grid } from '@material-ui/core'
import { Dialog } from 'frame/components'
import { useNavigate } from 'utils'
import { constants } from '../../module'
import Form from './form'
import ReactGA from 'react-ga'

const useStyles = makeStyles(theme => ({
  root: {},
}))

const New = ({
  relatedContract,
  fleetIds,
  fetchContractStatus,
  saveStatus,
  onFetchContract,
  onSave,
  onLookup,
  onReset,
  errorMessage,
  userRoles
}) => {

  const classes = useStyles()
  const navigate = useNavigate()
  const [saved, setSaved] = useState(false)

  const onClose = navigate(constants.PATH_ALL)
  const onCloseHandler = () => {
    onReset()
    onClose()
  }

  const onSubmit = (payload) => {
    console.log('[onSave]', JSON.stringify(payload, null, 2)) // eslint-disable-line
    console.log('[GA] event tracked', { category: 'Request', action: 'Submit New', label: 'Vehicle Relocation' })
    ReactGA.event({ category: 'Request', action: 'Submit New', label: 'Vehicle Relocation' })
    onSave(payload)
    setSaved(true)
  }

  // Same as componentDidUpdate
  // We check if the save was sucessful and the redirect.
  // TODO / Refactor - we may be able to do this in an effect.
  // We use the saved key here because we are also using global
  // state to manage the request status. Because we are not doing it
  // in place the global key will remain in it's previous state which
  // means without this check "saved" at the component level you will
  // not be able to add a second user... the form will keep closing.
  useEffect(() => {
    if (saved && saveStatus === 'processed') {
      onClose()
    }
  }, [saveStatus]) // eslint-disable-line

  const isSaving = saveStatus === 'processing'
  const savingError = saveStatus === 'error'

  return (
    <Dialog
      open autoSize
      size="md"
      aria-labelledby="Request Vehicle Relocation"
      breadcrumbs=""
      title={`Request Vehicle Relocation`}
      loading={isSaving}
      onClose={onCloseHandler}
      hideHeaderClose={true}
    >
      <div className={classes.root}>
        <Grid container>
          <Grid item md={12} xs={12}>
            <Form
              relatedContract={relatedContract}
              disabled={isSaving}
              fleetIds={fleetIds}
              error={savingError}
              fetchContractStatus={fetchContractStatus}
              onClose={onCloseHandler}
              onFetchContract={onFetchContract}
              onSubmit={onSubmit}
              onLookup={onLookup}
              errorMessage={errorMessage}
              userRoles={userRoles}
            />
          </Grid>
        </Grid>
      </div>
    </Dialog>
  )
}

New.propTypes = {
  relatedContract: PropTypes.object.isRequired,
  fleetIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  fetchContractStatus: PropTypes.string,
  saveStatus: PropTypes.string,
  onFetchContract: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onLookup: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  userRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default New

