import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { getStatusColor } from 'listable'
import { createRowSelector } from 'utils'
import { Label, FleetIcon } from 'frame/components'
import Options from './row-options'
import { types } from '../../module'
import { useEnabledFeatureToggleList } from 'features/settings';

import TableCellView from 'frame/components/table-cell'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import { Stack } from '@mui/material'

const Row = ({ item, columns, selected, onSelect, onView, onDelete, userRoles }) => {

  const onSafeRowClick = createRowSelector(onView)

  return (
    <TableRow
      hover
      sx={{
        boxSizing: 'border-box',
        cursor: 'pointer',
        verticalAlign: 'middle',
        height: '30px',
        borderBottom: '5px solid white',
      }}
      selected={selected.indexOf(item.id) !== -1}
      onClick={onSafeRowClick}
    >
      <TableCell sx={{ bgcolor: 'white', width: '10px', p: 0 }} />
      {columns.accountName && (
        <TableCellView isFirst>
          {item.accountName || '-'}
        </TableCellView>
      )}
      {columns.term && (
        <TableCellView>
          {item.term || '-'}
        </TableCellView>
      )}
      {columns.odometer && (
        <TableCellView>
          {item.odometer >= 0 ? item.odometer : '-'}
        </TableCellView>
      )}
      {columns.numberOfTyres && (
        <TableCellView>
          {item.numberOfTyres >= 0 ? item.numberOfTyres : '-'}
        </TableCellView>
      )}
      {columns.make && (
        <TableCellView>
          {item.make || '-'}
        </TableCellView>
      )}
      {columns.model && (
        <TableCellView>
          {item.model || '-'}
        </TableCellView>
      )}
      {columns.variant && (
        <TableCellView>
          {item.variant || '-'}
        </TableCellView>
      )}
      
      {columns.colour && (
        <TableCellView>
          {item.colour || '-'}
        </TableCellView>
      )}
      {columns.driveTrain && (
        <TableCellView>
          {item.driveTrain || '-'}
        </TableCellView>
      )}
      {columns.modifiedAt && (
        <TableCellView>
          {item?.modifiedAt ? moment(item?.modifiedAt).format('DD/MM/YY') : moment(item.createdAt).format('DD/MM/YY')}
        </TableCellView>
      )}
      {columns.leaseType && (
        <TableCellView>
          {item.leaseType || '-'}
        </TableCellView>
      )}
      {columns.requestType && (
        <TableCellView>
          {item.requestType || '-'}
        </TableCellView>
      )}
      {columns.status && (
        <TableCellView>
          {item.status ? (
            <Label
              color={getStatusColor(item.status)}
              variant="outlined">
              {item.status}
            </Label>
          ) : '-'}
        </TableCellView>
      )}
      <TableCellView sx={{ width: "50px", maxWidth: "50px", position: 'relative' }} isLast>
        <Stack
          sx={{
            flexDirection: 'row',
            position: 'absolute',
            right: 0,
            top: '50%',
            transform: 'translateY(-50%)',
            alignItems: 'center',
          }}
        >
          <Options
            className="ignore"
            id={item.id}
            onView={onView}
            onDelete={onDelete}
            userRoles={userRoles}
            status={item.status}
          />
        </Stack>
      </TableCellView>
    </TableRow>
  )
}

Row.propTypes = {
  item: types.ModelType.isRequired,
  columns: types.ColumnsType.isRequired,
  selected: types.IDListType.isRequired,
  onSelect: PropTypes.func,
  onView: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  userRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default Row
