import React, { useCallback, useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Listable } from 'listable'
import { useNavigate, useRouter } from 'utils'
import { actions, config, constants } from '../../module'
import { useEnabledFeatureToggleList, useSelectedFleetIds, useSelectedPremiumFleets } from 'features/settings'
import { moduleName } from '../../actions/types'
import { ChildRoutes } from '../../routes'
import { setFbtCustomerView } from '../../actions'
import PromotionBanner from 'features/fbt/promotion-banner'
import qs from 'query-string'
import Row from './row'
import ConfirmationPopups from '../confirmation-popups'
import Filters from '../filters'
import { hasRoles } from 'acl'

import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import SingleSelect from '../../../../frame/components/single-select'

const List = ({ model, currentFbtCustomerView,userRoles, onFilter, onRefetch, onClearFilters, onSelectFbtCustomer, onRequestFilters }) => {

  const fleetIds = useSelectedFleetIds()
  const linker = useNavigate()
  const { location } = useRouter()
  const fleetOptions = useSelectedPremiumFleets()

  const currentFleet = currentFbtCustomerView && fleetOptions.map(x => x.value).includes(currentFbtCustomerView)
    ? currentFbtCustomerView
    : fleetOptions[0]?.value

  const parsed = qs.parse(location.search)
  const tableName = `${model?.filtered.month} ${model?.filtered.year}` //`${capitalize(parsed.month)} ${parsed.year}`.trim()
  const featureToggleList = useEnabledFeatureToggleList()
  const userPermissionsFeatureToggle = featureToggleList.includes('UserPermissions')

   

  useEffect(() => {
    // onload redirect to latest month/year if not provided
    const yearsMonths = model?.filters?.yearsMonths
    !yearsMonths && onRequestFilters(fleetIds)
    
    const isEditPage = window.location.pathname.includes('/view-submissions') || window.location.pathname.includes('/add-submission')    
    if(!location.search && yearsMonths && !isEditPage) { // redirect to latest quarter/year if not provided, ignore on edit fbt routes
      const month = yearsMonths && yearsMonths.length > 0 ? yearsMonths[0].split(" - ")[1] : ''
      const year = yearsMonths && yearsMonths.length > 0 ? yearsMonths[0].split(" - ")[0] : ''
      yearsMonths && linker(`${constants.PATH_ALL}?year=${year}&month=${month}`)()
    }
    // when fleetid is not provided on report load for first time/after fleet changes
    !parsed?.fleetId && onSelectFbtCustomer(currentFleet)
    
  }, [fleetIds]) // eslint-disable-line

  useEffect(() => { // triggered on parsed month/fleetid changes (dropdown changes)
    if(parsed?.month && parsed?.year) {
      onRequestFilters(fleetIds)
      onFilter({ month: parsed.month, year: parsed.year, fleetId: parsed?.fleetId || currentFleet })
    }
  }, [parsed.month, parsed.fleetId]) // eslint-disable-line
  
  // for rendering rows
  const renderItem = useCallback((item, index, itemProps) => {
    const onEdit = linker(constants.PATH_SINGLE, item.id, `view-submissions`)
    return (
      <Row key={index} item={item} onEdit={onEdit} {...itemProps} />
    )
  }, []) // eslint-disable-line

  // Prevents re-rendering if ids or data updated - no longer needed
  const { ids, filters, ...rest } = model

  const onChangeFleet = (fleetId) => {
    onSelectFbtCustomer(fleetId) // update fleetId in redux
    linker(`${constants.PATH_ALL}?year=${parsed.year}&month=${parsed.month}&fleetId=${fleetId}`)()
  }
  
  const showPromotionBanner = fleetOptions.length < 1 || !model?.filters?.yearsMonths
  
  return (
    <>
      <Listable
        actions={actions}
        config={{ ...config, tableName }} // inject new config for dynamic table name
        filters={Filters}
        model={{ ...rest, filtering: !!model?.filtered?.completedStatus }}
        renderItem={renderItem}
        routes={ChildRoutes}
        actionButtons={
          <>
            <Stack
              flexDirection={{ xs: 'column', md: 'row' }}
              justifyContent='space-between'
              gap={2}
              paddingTop={1}
            >
              { !showPromotionBanner && (
                <Box 
                  width={{ xs: '100%', md: '205px' }}
                  sx={{
                    backgroundColor: '#FFF',
                    width: 300,
                  }}
                >
                  <SingleSelect
                    noNone tight
                    id="pageHeaderDropdown"
                    label={fleetOptions.length === 0 ? 'No FBT Customers Available' : ''}
                    options={fleetOptions}
                    value={currentFleet}
                    onChange={onChangeFleet}
                    disabled={fleetOptions.length <= 1}
                  />
                </Box>
              )}
              {!((userPermissionsFeatureToggle && hasRoles(['fleetcoordinator'], userRoles)) || showPromotionBanner) && (
                <Stack flexDirection="row" gap={2}>
                  <Box width={{ xs: "100%", md: "205px" }}>
                    <Button
                      fullWidth
                      color="primary"
                      variant="contained"
                      onClick={linker(constants.PATH_ADD)}
                    >
                      {"Add A New Driver Record"}
                    </Button>
                  </Box>
                </Stack>
              )}
            </Stack>
          </>
        }
        dropdown={{
          label: fleetOptions.length === 0 ? 'No FBT Customers Available' : '',
          value: currentFleet,
          options: fleetOptions,
          onChange: onChangeFleet,
          hide: showPromotionBanner,
          disable: fleetOptions.length <= 1
        }}
        customActions={{
          label: "Completed",
          onRefetch,
          onClearFilters: () => {
            onClearFilters()
            onFilter({ month: parsed.month, year: parsed.year, fleetId: currentFleet })
          }
        }}
        maskComponent={showPromotionBanner ? <PromotionBanner /> : null}
      />
      <ConfirmationPopups />
    </>
  )
}

List.propTypes = {
  model: PropTypes.object.isRequired,
  currentFbtCustomerView: PropTypes.string,
  userRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
  // func
  onFilter: PropTypes.func.isRequired,
  onRefetch: PropTypes.func.isRequired,
  onClearFilters: PropTypes.func.isRequired,
  onSelectFbtCustomer: PropTypes.func.isRequired,
  onRequestFilters: PropTypes.func.isRequired,
}

const mapStateToProps = ({ fbtSubmissions,settings }) => ({
  model: fbtSubmissions,
  currentFbtCustomerView: fbtSubmissions.currentFbtCustomerView,
  userRoles: settings.roles,
})

const mapDispatchToProps = (dispatch) => ({
  onFilter: (payload) => {
    dispatch({ type: `${moduleName}_update_filters`, payload })
  },
  onRefetch: () => {
    dispatch({ type: `${moduleName}_update_search_key`, payload: '' })
    dispatch({ type: `${moduleName}_refetch_resource` })
  },
  onClearFilters: () => {
    dispatch({ type: `${moduleName}_clear_filters` })
  },
  onSelectFbtCustomer: (payload) => {
    dispatch(setFbtCustomerView(payload))
  },
  onRequestFilters: (fleetIds) => {
    const FleetId = fleetIds.join(',')
    dispatch({
      type: `${moduleName}_request_filters`,
      payload: null,
      options: { queryParams: { FleetId } }
    })
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(List)
