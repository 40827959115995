import * as z from "zod";

export const schema = z.object({
  make: z
    .string()
    .optional(),
  model: z
    .string()
    .optional(),
  variant: z
    .string()
    .optional(),
  colour: z
    .string()
    .optional(),
  driveTrain: z
    .string()
    .optional(),
  vehicleNotes: z
    .string()
    .optional(),
});
