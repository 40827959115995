import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { ScrollToButton } from 'frame/components'
import { useEnabledFeatureToggleList } from 'features/settings'
import { useIsWhiteListed } from 'utils'

import Button from "@mui/material/Button"
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import Checkbox from "@mui/material/Checkbox"
import Divider from "@mui/material/Divider"
import FormControlLabel from "@mui/material/FormControlLabel"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import Stack from "@mui/material/Stack"
import { useTheme } from "@mui/material/styles"
import MuiTextfield from '../mui-textfield'

const Form = ({ fleets, values, onSubmit }) => {
  const theme = useTheme()
  const featureToggleList = useEnabledFeatureToggleList()
  const fbtFeatureToggle = featureToggleList.includes('FBT')
  const isWhiteListedUser = useIsWhiteListed()

  const topRef = useRef()
  const bottomRef = useRef()
  const [searchKey, setSearchKey] = useState('')
  const [filteredFleetItems, setFilteredFleetItems] = useState(fleets)

  const [fleetIds, setFleetIds] = useState(values)
  const [disabled, setDisabled] = useState(fleetIds.length > 0 ? false : true)

  const isSelected = fleetId =>
    fleetIds.includes(fleetId)

  const isSelectedAll = filteredFleetItems.filter(node => !isSelected(node.value)).length === 0

  const [selectAll, setSelectAll] = useState(isSelectedAll || false)

  const onSelect = (fleetId) => () => {
    if (isSelected(fleetId)) {
      const next = fleetIds.filter(id => id !== fleetId)
      setFleetIds(next)
      return
    }
    setFleetIds([...fleetIds, fleetId])
  }

  const onSelectAll = () => {
    const allFleets = filteredFleetItems.map(node => node.value)
    setFleetIds(selectAll ? [] : allFleets)
    setSelectAll(!selectAll)
  }

  const onSubmitForm = () =>
    fleetIds.length > 0 && onSubmit(fleetIds)

  useEffect(() => {
    setDisabled(fleetIds.length < 1)
  }, [fleetIds, setDisabled])

  
  const applySearch = (keyword) => {
    let filteredList = fleets
    if (!keyword || keyword === '') {
      filteredList = fleets
      setSearchKey(null)
    } else {
      filteredList = fleets
        .map(item => item.isPremium ? { ...item, label: `${item.label} *`  } : item)
        .filter(item => JSON.stringify(item.label).toLowerCase().includes(keyword.toLowerCase()))
        .map(item => item.isPremium ? { ...item, label: `${item.label}`.replace(' *', '')  } : item)
    }
    setFilteredFleetItems(filteredList)
    setSearchKey(keyword)
  }

  const onSearchChange = ({ target: { value } }) => {
    setSearchKey(value)
    applySearch(value)
  }

  const ButtonCtas = () => {
    return (
      <Stack flexDirection="row" justifyContent="space-between" gap={1} py={1}>
        <Stack width="205px">
          <Button
            fullWidth
            color="primary"
            disabled={disabled}
            type="submit"
            variant="contained"
            onClick={() => onSubmitForm()}
          >
            Select ({fleetIds.length})
          </Button>
        </Stack>
        <Stack width="205px">
          <Button
            id={selectAll  ? 'unselect-btn' : 'select-btn'}
            variant="outlined"
            onClick={() => onSelectAll()}
          >
            {selectAll ? 'Unselect All' : 'Select All'}
          </Button>
        </Stack>
      </Stack>
    )
  }

  return (
    <>
      <div ref={topRef} />
      <Card
        sx={{
          boxShadow: 'none',
          borderRadius: '5px',
        }}
      >
        <CardContent
          sx={{
            p: 3,
          }}
        >
          <Stack
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            gap={3}
          >
            <Stack flex={1}>
              <Typography variant="h5" fontWeight={600}>
                Fleets
              </Typography>
              <Typography variant="bodyMedium">
                Please select the fleets you wish to view data for.
              </Typography>
            </Stack>
            <Stack flex={1}>
              <MuiTextfield
                id="fleet-search"
                label="Fleet Search"
                value={searchKey}
                onChange={onSearchChange}
              />
            </Stack>
          </Stack>

          <Divider sx={{ py: 2 }} />

          <Stack>
            <Stack py={2}>
              <ButtonCtas />
              
              <Grid container py={2}>
                {filteredFleetItems.map((node, index) => (
                  <Grid key={index} item sm={6} xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isSelected(node.value)}
                          onChange={onSelect(node.value)}
                          value={node.value}
                          color="default"
                          sx={{
                            color: theme.palette.accent.sky,
                            '&.Mui-checked': {
                              color: theme.palette.accent.sky,
                            },
                          }}
                        />
                      }
                      label={fbtFeatureToggle && isWhiteListedUser
                        ? `${node.label}${node?.isPremium ? ' *' : ''}`
                        : node.label
                      }
                    />
                  </Grid>
                ))}
              </Grid>

              <ButtonCtas />

            </Stack>
          </Stack>

          {/* scroll to bottom button*/}
          <ScrollToButton scrollDirection="up" scrollToRef={topRef} />
          <ScrollToButton scrollToRef={bottomRef} />
          
        </CardContent>
        <div ref={bottomRef} />
      </Card>
    </>
  )
}

Form.propTypes = {
  fleets: PropTypes.array.isRequired,
  values: PropTypes.array.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

export default Form
