import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { StyledModal, AnimatedSuccessTick, AnimatedErrorCross, SelectableValueText } from "frame/components"
import ReactGA from "react-ga"
import { hasRoles } from "acl"
import { useEnabledFeatureToggleList } from "features/settings"

import Button from "@mui/material/Button"
import TableContainer from "@mui/material/TableContainer"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import TableCellView from "frame/components/table-cell"
import { useTheme } from "@mui/material/styles"

import { useForm, Controller, FormProvider } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import MuiTextfield from "frame/components/mui-textfield/mui-textfield"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import MuiFormSelect from "frame/components/mui-form-select"

import { schema } from "./variations-requests-table-schema"
import StatusMenu from "./status-menu"
import { VariationsRequestTableLoading } from "./variations-requests-table-loading"
import DatePicker from "frame/components/date-picker-v5"
import StyledModalV2, { StyledModalV2Actions } from "frame/components/styled-modal-v2"

const newVariationState = {
  id: 0,
  agreementNo: null,
  thirdPartyNumber: null,
  ordNumber: 1,
  maturityDate: dayjs().add(1, "months"),
  contractKm: 0,
  noOfTyres: 0,
  currentOdometer: 0,
  status: "requested",
  isNew: true,
}

const VariationRequestsTable = ({
  readOnly,
  data,
  flags,
  errorMessage,
  userRoles,
  onUpdate,
  onDeleteVariation,
  setUpdatingStatus,
  isLoading,
}) => {
  const theme = useTheme()
  const featureToggleList = useEnabledFeatureToggleList()
  const userPermissionsFeatureToggle = featureToggleList.includes("UserPermissions")
  const [fleetId] = useState(data.thirdPartyNumber)
  const [originalVariations, setOriginalVariations] = useState(data.variations)
  const [isEditingKey, setIsEditingKey] = useState()
  const [isSubmitting, setSubmitting] = useState(false)

  const [requestVariations, setRequestVariations] = useState(data.variations ? data.variations : [])
  const [deleteVariationValue, setDeleteVariationValue] = useState(null)
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false)
  const [openStatusConfirm, setOpenStatusConfirmModal] = useState(false)

  const [values, setValues] = useState({ // for new variation entry row
    agreementNo: data.agreementNo,
    thirdPartyNumber: data.thirdPartyNumber,
    ...newVariationState
  })

  const methods = useForm({
    resolver: zodResolver(schema),
  })

  useEffect(() => { // update local state when data changes after fetch
    if(data.variations && JSON.stringify(data.variations) !== JSON.stringify(requestVariations)) {
      setRequestVariations(data.variations)
    }// eslint-disable-next-line
  }, [data.variations])
  
  useEffect(() => { // open confirmation modal on success/error flag
    if(flags.update === "processed" || flags.update === "error" || flags.deleteVariation === "processed" || flags.deleteVariation === "error") {
      setOpenConfirmationModal(true)
    }
  }, [flags.update, flags.deleteVariation])

  const onSubmitForm = () => {
    setSubmitting(true)
    let newVariations = requestVariations
    newVariations.push(values) // push new variation row to existing variations array
    setOriginalVariations(newVariations) // update original values

    const payload = {
      id: data.id,
      agreementNo: data?.agreementNo,
      thirdPartyNumber: data?.thirdPartyNumber,
      variations: newVariations,
      vehicleNotes: values.vehicleNotes,
      driverNotification: values.driverNotification
    }

    if (payload.variations.length > 0)
    {
      console.log("[GA] event tracked", { category: "Request", action: "Submit New", label: "Contract Variations" })
      ReactGA.event({ category: "Request", action: "Submit New", label: "Contract Variations" })
    }
    console.log("[Variations onSubmitForm]: ", payload)
    setSubmitting(false)
    onUpdate(payload)
    setIsEditingKey(null) // reset any editable status that is active
    onClear() // reset values for new entry
  }

  const onSelectChange = (key, value) => {
    console.log(key, value)
    setValues({ ...values, [key]: value })
  }
    

  const onInputChange = (key) => ({ target: { value } }) => {
    setValues({ ...values, [key]: value })
    methods.setValue(key, value, {
      shouldValidate: true,
      shouldDirty: true,
    })
  }

  const onSubmitNewRequestVariation = () => console.log("onSubmitNewRequestVariation")
    // form.current && form.current.submit()

  const onDeleteVariationHandler = () => {
    if(deleteVariationValue && deleteVariationValue.id !== 0) {
      console.log("[onDeleteVariationHandler]: ", { deleteVariationValue, fleetId })
      onDeleteVariation(deleteVariationValue.id, fleetId)
      setIsEditingKey(null) // reset any editable status that is active
      setDeleteVariationValue(null)
    } else {
      console.log("[onDeleteVariationHandler]: error ", { deleteVariationValue, fleetId })
    }
  }

  const onClear = () =>
    setValues({
      ...values,
      maturityDate: dayjs().add(1, "months").format("YYYY-MM-DD"),
      contractKm: 0,
      noOfTyres: 0,
      currentOdometer: 0,
    })
  
  // request variation button validation 
  const invalidVariationDate = dayjs(values.maturityDate).isBefore(dayjs().add(1, "months"), "month")
    
  const level1StatusOptions = ["requested", "accepted", "cancelled"]
  const level2StatusOtions = ["accepted", "cancelled", "completed"]

  const onSaveStatus = () => {
    setUpdatingStatus(true) // to customize confirmation message
    const payload = {
      id: data.id,
      agreementNo: data?.agreementNo,
      thirdPartyNumber: data?.thirdPartyNumber,
      variations: requestVariations,
      vehicleNotes: values.vehicleNotes,
      driverNotification: values.driverNotification
    }
    console.log("[Variations onSaveStatus]: ", payload)
    setOpenStatusConfirmModal(false)
    onUpdate(payload)
  }

  const onStatusChange = (id, option) => {
    const variations = requestVariations.map((item) => id === item.id 
      ? { ...item, status: option.value || option } 
      : item
    )
    console.log("onStatusChange", { id, option, variations, requestVariations, originalVariations })
    setRequestVariations(variations)
    setOpenStatusConfirmModal(true)
  }

  const onCancelChange = (id) => () => {
    const variationList = requestVariations.map((item, index) => id === item.id 
      ? { ...item, status: originalVariations[index].status } 
      : item
    )
    console.log("onCancelChange", { id, requestVariations, originalVariations })
    setRequestVariations(variationList)
  }
  
  const onEdit = (key, id) => () => { // function passed into SelectableValueText component to call on edit mode select
    // reset all values, except current key
    const variationList = requestVariations.map((item, index) => id === item.id ? item : originalVariations[index])
    console.log("onCancelChange", { id, requestVariations, originalVariations })
    setRequestVariations(variationList)

    setIsEditingKey(key)  // reset other fields out of edit mode
  }
  
  const newVariationRow = userPermissionsFeatureToggle ? hasRoles(["superadmin", "fleetcontroller"], userRoles) && requestVariations && requestVariations.length < 3 : requestVariations && requestVariations.length < 3

  return (
    <>
      <Stack gap={2}>
        <Stack
          borderRadius="5px"
          border="1px solid"
          borderColor={theme.palette.accent.sky}
          flexDirection="row"
          flexWrap="wrap"
          overflow="hidden"
          p={2}
        >
          <TableContainer>
            <Table
              sx={{
                p: 2,
                borderSpacing: "0 5px",
              }}
            >
              <TableHead>
                <TableRow
                  sx={{
                    height: "30px",
                    borderBottom: "5px solid white"
                  }}
                >
                  {[
                    { heading: "New Maturity Date", minWidth: 140 },
                    { heading: "Total Kilometres", minWidth: 130 },
                    { heading: "Additional Tyres", minWidth: 130 },
                    { heading: "Current Odometer", minWidth: 130 },
                    { heading: "Status", minWidth: 120, align: "left" },
                    { heading: "", minWidth: 0 },
                  ]
                  .map(({ heading, minWidth }, index) => (
                    <TableCellView
                      key={`variation-table-heading-${index}`}
                      sx={{ minWidth, bgcolor: "white" }}
                    >
                      {heading}
                    </TableCellView>
                  ))}
                </TableRow>
              </TableHead>
              {isLoading ? (
                <VariationsRequestTableLoading />
              ): (
                <TableBody>
                  {requestVariations.length > 0 && requestVariations.map((row, index) => (  
                    <TableRow
                      key={`variation-row-${index}`}
                      sx={{
                        height: "40px",
                        borderBottom: "5px solid white",
                      }}
                    >
                      <TableCellView isFirst>
                        <Typography variant="body3">
                          {row.maturityDate ? dayjs(row.maturityDate).format("MMMM YYYY") : "-"}
                        </Typography>
                      </TableCellView>

                      <TableCellView>
                        <Typography variant="body3">
                          {row.contractKm ? parseInt(row.contractKm, 10).toLocaleString("en-NZ") : "-"}
                        </Typography>
                      </TableCellView>

                      <TableCellView>
                        <Typography variant="body3">
                          {row.noOfTyres ? row.noOfTyres : "-"}
                        </Typography>
                      </TableCellView>

                      <TableCellView>
                        <Typography variant="body3">
                          {row.currentOdometer ? parseInt(row.currentOdometer, 10).toLocaleString("en-NZ") : "-"}
                        </Typography>
                      </TableCellView>

                      <TableCellView sx={{ textTransform: "uppercase" }}>
                        {row.status ?? "-"}
                      </TableCellView>
                      <TableCellView isLast>
                        <StatusMenu
                          options={row.status && row.status.toLowerCase() === "requested" ? level1StatusOptions : level2StatusOtions}
                          onChange={(value) => onStatusChange(row.id, value)}
                          onDelete={() => setDeleteVariationValue(row)}
                        />
                      </TableCellView>
                    </TableRow>
                  ))}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Stack>
        

        {requestVariations.length !== 3 && (
          <Stack
            borderRadius="5px"
            border="1px solid"
            borderColor={theme.palette.accent.sky}
            flexDirection={{ xs: "column", md: "row" }}
            gap={2}
            flexWrap="wrap"
            p={3}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <FormProvider>
                <form
                  id="variation-request-form"
                  onSubmit={methods.handleSubmit(onSubmitForm)}
                  style={{ flex: 1 }}
                >
                  <Stack flexDirection={{ xs: "column", md: "row" }} gap={2}>
                    <Stack flex={1}>
                      <DatePicker
                        variant="filled"
                        label="New Maturity"
                        format="MMM YYYY"
                        minDate={dayjs().add(1, "months")}
                        value={typeof values.maturityDate === "undefined" ? null : dayjs(values.maturityDate)}
                        views={["year", "month"]}
                        onChange={(value) => onSelectChange("maturityDate", value)}
                      />
                    </Stack>

                    <Stack flex={1} gap={1}>
                      <Controller
                        name="contractKm"
                        control={methods.control}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <MuiTextfield
                            {...field}
                            id="contractKm"
                            // disabled={readOnly}
                            label="Total Kilometres"
                            error={Boolean(methods.formState.errors?.contractKm?.message)}
                            value={values.contractKm || ""}
                            onChange={onInputChange("contractKm")}
                          />
                        )}
                      />
                      <Typography color="error" fontSize={12}>
                        {methods.formState.errors?.contractKm?.message}
                      </Typography>
                    </Stack>

                    <Stack flex={1} gap={1}>
                      <Controller
                        name="noOfTyres"
                        control={methods.control}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <MuiFormSelect
                            {...field}
                            id="noOfTyres"
                            labelId="noOfTyres-label"
                            // disabled={readOnly}
                            label="Tyres"
                            options={[0, 2, 4, 6, 8, 10, 12]}
                            value={values.noOfTyres ?? 0}
                            onChange={(event) => onSelectChange("noOfTyres", event.target.value)}
                          />
                        )}
                      />
                      <Typography color="error" fontSize={12}>
                        {methods.formState.errors?.noOfTyres?.message}
                      </Typography>
                    </Stack>
                    <Stack flex={1} gap={1}>
                      <Controller
                        name="currentOdometer"
                        control={methods.control}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <MuiTextfield
                            {...field}
                            id="currentOdometer"
                            label="Current Odometer"
                            error={Boolean(methods.formState.errors?.currentOdometer?.message)}
                            value={values.currentOdometer || ""}
                            onChange={onInputChange("currentOdometer")}
                          />
                        )}
                      />
                      <Typography color="error" fontSize={12}>
                        {methods.formState.errors?.currentOdometer?.message}
                      </Typography>
                    </Stack>
                    <Stack minWidth="100px">
                      <Button
                        fullWidth
                        id="request-variation-button"
                        data-cy="request-variation-button"
                        variant="contained"
                        type="submit"
                        // disabled={(userPermissionsFeatureToggle && hasRoles(["fleetcoordinator"], userRoles)) || invalidVariationDate || readOnly }
                        disabled={isSubmitting}
                        sx={{
                          minHeight: "48px"
                        }}
                      >
                        Request
                      </Button>
                    </Stack>
                  </Stack>
                </form>
              </FormProvider>
            </LocalizationProvider>
          </Stack>
        )}
      </Stack>


      {/* Delete Variation Confirmation Modal */}
      <StyledModalV2
        open={deleteVariationValue ? true : false}
        onClose={() => setDeleteVariationValue(null)}
        actions={
          <StyledModalV2Actions
            onCancel={() => setDeleteVariationValue(null)}
            onConfirm={onDeleteVariationHandler}
          />
        }
        content={
          <Typography variant="h4" textAlign="center">
            Please confirm that you wish to delete this variation request.
          </Typography>
        }
      />

      <StyledModalV2
        open={openConfirmationModal}
        onClose={() => {
          setOpenConfirmationModal(false)
          setUpdatingStatus(false)
        }}
        status={flags.update === "processed" || flags.deleteVariation === "processed" ? "success" : "error"}
        content={
          <Typography variant="h4" textAlign="center">
            {flags.update === "processed"
              ? flags.updatingStatus
                ? "Contract Variation status updated successfully."
                : "Contract Variation requested successfully."
              : flags.deleteVariation === "processed"
                ? "Contract Variation deleted successfully."
                : flags.deleteVariation === "error"
                    ? `Contract Variation delete failed. ${errorMessage}`.trim()
                    : `Contract Variation request failed. ${errorMessage}`.trim()}
          </Typography>
        }
      />
      
      {/* Confirm status update */}
      <StyledModalV2
        open={openStatusConfirm}
        onClose={() => setOpenStatusConfirmModal(false)}
        actions={
          <StyledModalV2Actions
            onCancel={() => setOpenStatusConfirmModal(false)}
            onConfirm={() => onSaveStatus()}
          />
        }
        content={
          <Typography variant="h4" textAlign="center">
            Are you sure to update the status?
          </Typography>
        }
      />
    </>
  )
}

VariationRequestsTable.propTypes = {
  readOnly: PropTypes.bool,
  data: PropTypes.object.isRequired,
  flags: PropTypes.object.isRequired,
  errorMessage: PropTypes.string.isRequired,
  userRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
  // func
  onUpdate: PropTypes.func.isRequired,
  onDeleteVariation: PropTypes.func.isRequired,
  setUpdatingStatus: PropTypes.func.isRequired,
}

export default VariationRequestsTable