import { combineEpics, ofType } from 'redux-observable'
import { of } from 'rxjs'
import { map, mergeMap } from 'rxjs/operators'
import { makeActionRequestEffect } from 'effects'
import { getAppConfig } from 'app-config'
import join from 'url-join'
import * as fromModuleTypes from './actions/types'


export const createEffects = (moduleActions) => {

  const appConfig = getAppConfig()

  const createEffect = makeActionRequestEffect({
    type: fromModuleTypes.CREATE,
    url: join(appConfig.app.api, 'vehiclerelocation/vehiclerelocation'),
    verb: 'post',
    onSuccess: moduleActions.createFulfilled,
    onError: moduleActions.createError,
  })

  const fetchEffect = makeActionRequestEffect({
    type: fromModuleTypes.FETCH,
    url: join(appConfig.app.api, 'vehiclerelocation/vehiclerelocation'),
    onSuccess: moduleActions.fetchFulfilled,
    onError: moduleActions.fetchError,
  })

  // const onFetchedEffect = (action$, state$) =>
  //   action$.pipe(
  //     ofType(fromModuleTypes.FETCH_FULFILLED),
  //     map(({ payload }) => {
  //       const options = {
  //         resourceId: payload.vehicleRegistration,
  //         queryParams: { agreementNo: payload.agreementNo },
  //       }
  //       return moduleActions.fetchCorrespondingContract({}, options)
  //     })
  //   )

  // const fetchCorrespondingContractEffect = makeActionRequestEffect({
  //   type: fromModuleTypes.FETCH_CORRESPONDING_CONTRACT,
  //   url: join(appConfig.app.api, 'vehicle/getreferencedvehicledata'),
  //   onSuccess: moduleActions.fetchCorrespondingContractFulfilled,
  //   onError: moduleActions.fetchCorrespondingContractError,
  // })

  const updateEffect = makeActionRequestEffect({
    type: fromModuleTypes.UPDATE,
    url: join(appConfig.app.api, 'vehiclerelocation/vehiclerelocation'),
    verb: 'put',
    onSuccess: moduleActions.updateFulfilled,
    onError: moduleActions.updateError,
  })

  const fetchContractEffect = makeActionRequestEffect({
    type: fromModuleTypes.FETCH_CONTRACT,
    url: join(appConfig.app.api, 'contract/contract'),
    onSuccess: moduleActions.fetchContractFulfilled,
    onError: moduleActions.fetchContractError,
  })

  const deleteEffect = makeActionRequestEffect({
    type: fromModuleTypes.DELETE,
    url: join(appConfig.app.api, 'vehiclerelocation/delete'),
    verb: 'delete',
    onSuccess: moduleActions.removeFulfilled,
    onError: moduleActions.removeError,
  })

  const refetchEffect = (action$, state$) =>
    action$.pipe(
      ofType(
        fromModuleTypes.CREATE_FULFILLED,
        fromModuleTypes.UPDATE_FULFILLED,
        fromModuleTypes.DELETE_FULFILLED
      ),
      mergeMap(() => of(
        moduleActions.refetch(),
      ))
    )

  const lookupEffect = makeActionRequestEffect({
    type: fromModuleTypes.LOOKUP,
    url: join(appConfig.app.api, `lookup`),
    onSuccess: moduleActions.lookupFulfilled,
    onError: moduleActions.lookupError,
  })

  return combineEpics(
    createEffect,
    fetchEffect,
    // fetchCorrespondingContractEffect,
    fetchContractEffect,
    updateEffect,
    refetchEffect,
    // onFetchedEffect,
    deleteEffect,
    lookupEffect,
  )
}
