import * as z from "zod";

export const schema = z.object({
  status: z
    .string()
    .optional(),
  vehicleType: z
    .string()
    .optional(),
  pickupAddress: z
    .string({ required_error: "This field is required" })
    .min(1, { message: "Please enter a pickup address" }),
  comments: z
    .string()
    .optional(),
});
